<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Defina produtos em oferta com duração customizada, selecione período e lojas em que a oferta será aplicada.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-store color-theme font-11 me-1"></i> Loja</label>
							<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.loja" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Todas as lojas">
								<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
								<template v-slot:no-options>
									<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
								</template>
							</v-select>
							<select name="Mobile" class="form-control" v-model="pesquisa.loja">
								<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
							</select>
                  </div>
                  <div class="col-sm-6 col-lg-3 mb-3">
							<label><i class="far fa-exchange-alt color-theme font-11 me-1"></i> Status</label>
							<v-select name="Desktop" :options="['Em andamento', 'Pendente', 'Expirado']" v-model="pesquisa.status" :searchable="false" placeholder="Todos os status" />
							<select name="Mobile" class="form-control" v-model="pesquisa.status">
								<option v-for="(option, index) in ['Em andamento', 'Pendente', 'Expirado']" :key="index" :value="option">{{ option }}</option>
							</select>
                  </div>
                  <div class="col-md-8 col-lg-3 col-xxl-4 mb-3">
							<label><i class="far fa-percentage color-theme me-1"></i> Nome</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarOfertas" placeholder="Busque por nome da oferta" />
                  </div>
						<div class="col-md-4 col-lg-3 col-xxl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarOfertas"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar ofertas')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                        </div>
                     </div>
						</div>
					</div>
            </div>
         </div>
      </div>

      <div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<oferta v-for="(oferta, index) in pesquisa.retorno.resultado" :key="index" :oferta="oferta" :index="index" @editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

		<!-- modalOferta -->
      <div class="modal fade" id="modalOferta" tabindex="-1" aria-labelledby="modalOfertaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalOfertaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} oferta {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-md-inline"> Principal</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-lojas-tab" data-bs-toggle="pill" data-bs-target="#pills-lojas" type="button" role="tab" aria-controls="pills-lojas" aria-selected="false">
											<i class="far fa-store font-12"></i><span class="ms-1 d-none d-md-inline"> Lojas</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-produtos-tab" data-bs-toggle="pill" data-bs-target="#pills-produtos" type="button" role="tab" aria-controls="pills-produtos" aria-selected="false">
											<i class="far fa-carrot font-12"></i><span class="ms-1 d-none d-md-inline"> Produtos</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card mb-5">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
											</div>
											<div class="col-12">
												<label><i class="far fa-calendar color-theme font-11 me-1"></i> Período *</label>
												<datePicker format="DD/MM/YYYY" :range="true" :clearable="false" :editable="false" v-model="modal.data"></datePicker>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-lojas" role="tabpanel" aria-labelledby="pills-lojas-tab">
								<div class="row">
									<loja v-for="(loja, index) in modal.lojas" :key="index" :index="index" :loja="loja" class="px-0"/>

									<div class="col-12 my-5 text-center" v-if="modal.lojas.length == 0">
										<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
										<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma loja encontrada</h4>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-produtos" role="tabpanel" aria-labelledby="pills-produtos-tab">
								<div class="card mb-0">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 mb-3">
												<h6 class="mb-0 mt-1 font-15">
													<span class="mb-0 font-14 weight-400">{{ modal.produtos.length }} produto(s) encontrado(s)</span>
													<a href="javascript:;" class="float-end font-16" data-bs-toggle="modal" data-bs-target="#modalProdutoPreco"><i class="fas fa-plus color-theme"></i></a>
												</h6>
											</div>
		
											<produto v-for="(produto, index) in modal.produtos" :key="index" :produto="produto" :index="index" :campos="{'preco': true}"
												@remover="removerProduto($event)" />
		
											<div class="col-12 my-5 text-center" v-if="modal.produtos.length == 0">
												<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
												<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarOferta">Salvar</button>
						<button type="button" class="btn btn-outline-primary" v-if="modal.id" @click="imprimir">Imprimir</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
					</div>
				</div>
			</div>
		</div>

		<modalProdutoPreco :modalAnterior="'#modalOferta'" @addProduto="addProduto($event)" />

		<impressao id="impressao" class="d-none" :titulo="$route.name.replace(/_/g, ' ').replace(/-/g, '/')" :data="new Date()" :cabecalho="{
			'Nome oferta': modal.nome == null ? ' - ' : modal.nome, 
			'Período': modal.data == null || modal.data[0] == null || modal.data[1] == null ? ' - ' : 
				(new Date(modal.data[0]).getDate().toString().padStart(2, '0') +'/'+ (new Date(modal.data[0]).getMonth() + 1).toString().padStart(2, '0') +'/'+ new Date(modal.data[0]).getFullYear() 
				+' - '+ new Date(modal.data[1]).getDate().toString().padStart(2, '0') +'/'+ (new Date(modal.data[1]).getMonth() + 1).toString().padStart(2, '0') +'/'+ new Date(modal.data[1]).getFullYear()), 
			'Lojas': String(modal.lojas.filter(l => l.habilitado).map(l => l.nomeFantasia)).replace(/,/g, ', ')
			}" :itensKey="{'nome': 'Descrição', 'preco': 'Preço'}" :itens="modal.produtos" />
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import oferta from '@/components/automacao/ofertas/Oferta.vue'
import impressao from '@/components/impressao/ComprovanteA4.vue'
import loja from '@/components/configuracoes/usuarios/CheckLoja.vue'
import produto from '@/components/automacao/ofertas/Produto.vue'
import modalProdutoPreco from '@/components/produtos/ModalProdutoPreco.vue'

export default {
	name: 'Ofertas',
	data: function () {
		return {
			pesquisa: {'loja': null, 'status': null, 'nome': '', 'retorno': {'loja': null, 'status': null, 'nome': null, 'resultado': []}},
			modal: {'id': null, 'nome': null, 'data': [new Date(), new Date()], 'lojas': [], 'produtos': []}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
			tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      }
	},
	components: {
		oferta, loja, produto, impressao, modalProdutoPreco
	},
	methods: {
		imprimir : function () {
         setTimeout(() => this.$htmlToPaper('impressao', {styles: ['./comprovanteA4.css']}), 250);
      },
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Oferta',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nome', 'dataCriacao', 'inicio', 'fim'], 
				'lista': {'nome': 'lojas', 'campos': ['numeroLoja', 'nomeFantasia']}
			})
      },
		abrirModal : function (oferta) {
			this.modal = {'id': null, 'nome': null, 'data': [new Date(), new Date()], 'lojas': JSON.parse(JSON.stringify(this.dadosUsuario.usuarioLojas)), 'produtos': []}

			if (oferta != null) {
				this.buscarOferta(oferta)
			} else {
				$('#modalOferta').modal('show')
			}
		},
		buscarOfertas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/searchOfertas',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarOferta : function (oferta) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'automacao/getOferta',
				params: {
					id: oferta.id
				}
			}).then(response => {
				$('#modalOferta').modal('show')

				this.modal = {
					'id': response.data.id, 
					'nome': response.data.nome, 
					'data': [new Date(response.data.inicio), new Date(response.data.fim)], 
					'lojas': JSON.parse(JSON.stringify(response.data.lojas)), 
					'produtos': JSON.parse(JSON.stringify(response.data.produtos))
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		addProduto : function (produto) {
			this.modal.produtos.push(JSON.parse(JSON.stringify(produto)))
		},
		removerProduto : function (index) {
			this.modal.produtos.splice(index, 1)
		},
		salvarOferta : function () {
			if (this.modal.nome == null || String(this.modal.nome).trim().length == 0 || this.modal.produtos.length == 0 || this.modal.lojas.findIndex(loja => loja.habilitado) == -1) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (this.modal.produtos.filter(p => !/^[0-9.]+$/.test(p.preco) || parseFloat(p.preco) < 0.01).length > 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Existem produtos com preço inválido!'
				});
	
				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'automacao/saveOferta',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalOferta').modal('hide')
				this.buscarOfertas()

				this.$toast.fire({
					icon: 'success',
					title: 'Oferta salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>