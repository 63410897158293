<template>
   <div class="col-12 px-0 mb-1" ref="oferta">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-sm text-center text-sm-start cursor-pointer" @click="editar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ oferta.nome }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary"><i class="far fa-hashtag font-9 me-1"></i>{{ oferta.id }}</p>
                     </div>
                     <div class="col-6 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar-day color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Data criação:</strong>
                           <span>{{ oferta.dataCriacao.dayOfMonth.toString().padStart(2, '0') +'/'+ oferta.dataCriacao.monthValue.toString().padStart(2, '0') +'/'+ oferta.dataCriacao.year }} </span>
                           <span>{{ oferta.dataCriacao.hour.toString().padStart(2, '0') +':'+ oferta.dataCriacao.minute.toString().padStart(2, '0') }}</span>   
                        </p>
                     </div>
                     <div class="col">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Período:</strong>
                           <span>{{ String(oferta.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(oferta.inicio.monthValue).padStart(2, '0') +'/'+ oferta.inicio.year }} até</span>
                           <span> {{ String(oferta.fim.dayOfMonth).padStart(2, '0') +'/'+ String(oferta.fim.monthValue).padStart(2, '0') +'/'+ oferta.fim.year }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-sm-0 my-2 my-sm-0" v-if="dadosUsuario.usuarioPermissoes.includes('Automação | Editar ofertas')">
                  <div class="btn-icone color-theme">
                     <span @click="editar"><i class="far fa-pen "></i><small>Editar</small></span>
                  </div>
                  <div class="btn-icone text-red">
                     <span @click="remover"><i class="far fa-trash "></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Oferta',
   props: ['oferta', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      editar : function () {
         if (this.dadosUsuario.usuarioPermissoes.includes('Automação | Editar ofertas')) {
            this.$emit('editar', this.oferta)
         }
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir oferta?',
            text: 'A oferta será excluída, essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'automacao/deleteOferta',
                  params: {
                     id: this.oferta.id
                  }
               }).then(() => {
                  this.$refs.oferta.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: 'Oferta excluída!'
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         });
      }
   }
}

</script>